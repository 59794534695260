<template>
  <b-row>
    <b-col class="col-12 col-md-6 d-flex align-items-stretch mb-1" v-for="(card, index) in cards_works" :key="index">
      <card-work :data="card" @click_action="redirectAction"/>
    </b-col>
  </b-row>
</template>
  
<script>
import { BCol, BRow, } from "bootstrap-vue";
import proposals_services from '@/services/proposals';
import { numberFormat } from '@/libs/utils/formats'
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');
import { getUserData } from '@/libs/utils/user';

export default {
  name: "desk",
  components: {
    BCol,
    BRow,
    cardWork: () => import('@/views/pages/proposals/cardWork.vue'),
  },
  data() {
    return {
      active_proposals: 0,
      proposal_published: 0,
      numberFormat,
      cards_works: [
        {
          title: this.$t('creator.attractBrands'),
          icon: 'back-money',
          text: this.$t('creator.proposalsPublished'),
          text_action: this.$t('dashboard.createProposal'),
          background_left: 'linear-gradient(0deg, #A24EF0, #994EFF)',
          backgrounds: {
            container: 'linear-gradient(0deg, #A24EF0, #994EFF)',
            container_hover: '#a24ef038',
            filter_svg: 'invert(55%) sepia(95%) saturate(2200%) hue-rotate(270deg) brightness(50%) contrast(100%)',
            selected: 'linear-gradient(0deg, #A24EF0, #994EFF)',
          },
          card_action: 'redirectProposal',
        },
        {
          title: this.$t('creator.findWork'),
          icon: 'marketing-enginees',
          text: this.$t('creator.campaignsLaunched'),
          text_action: this.$t('creator.viewCampaign'),
          background_left: 'linear-gradient(180deg, #11F0E5, #21D3E1)',
          backgrounds: {
            container: 'linear-gradient(180deg, #11F0E5, #21D3E1)',
            container_hover: '#11f0e523',
            filter_svg: 'invert(35%) sepia(55%) saturate(2200%) hue-rotate(180deg) brightness(120%) contrast(120%)',
            selected: 'linear-gradient(180deg, #11F0E5, #21D3E1)',
          },
          card_action: 'redirectCastingCalls'
        }
      ]
    }
  },
  created() {
    this.getMyWorkSummary();
  },
  computed: {
    create_proposal() {
      return getUserData() && getUserData().meta_info;
    },
  },
  methods: {
    redirectAction(method) {
      this[method]();
    },
    getMyWorkSummary() {
      proposals_services.myWorkSummary().then(response => {
        this.active_proposals = response.active_proposals;
        this.proposal_published = response.proposal_published
      })
    },
    redirectCastingCalls() {
      this.$router.push({name: 'casting_calls'})
    },
    redirectProposal() {
      if (this.create_proposal) {
        this.$router.push({ name: 'steps_proposal', params: {step: 'category'}})
      } else {
        this.$router.push({ name: 'my_work', params: { section: 'networks' } }).catch(() => {})
        loadToastificationContent().then((component) => {
          this.$toast({
            component: component.default,
            position: 'top-right',
            props: {
              title: this.$t('creator.createProfilePublic'),
              text: this.$t('creator.msjCreateProfilePublicProposals'),
              icon: 'AlertCircleIcon',
              variant: 'danger',
            }
          })
        })
      }
    }
  },
};
</script>
